<template>
  <div>
    <footer v-if="language == 'ko'" class="beauty_footer section" id="contact">
      <div class="inner">
        <div class="inner-left">
          <div class="footer-intro">
            <div class="logo-img"></div>
            <p class="footer-intro-text1">
              i-ESG는 빠르게 확대되는 ESG 분야 다양한 요구를 효과적으로 관리하는 데이터기반 디지털
              서비스 플랫폼입니다. 기업들에게 필요한 서비스를 제공하기 위해, i-ESG는 혁신적인 기술을
              활용하여 객관성과 신뢰성에 기반한 서비스를 제공합니다. 효과적인 ESG 관리, i-ESG에서
              시작하세요!
            </p>
            <p class="footer-intro-text2">
              i-ESG is a data-driven digital integrated solution company specialized in the ESG
              sector. To provide more practical support to company customers, we use the latest
              technologies for purposes such as big data analysis, AI algorithms, and blockchain
              technology. To better manage your ESG, i-ESG is the most adequate solution for SMEs.
            </p>
          </div>
          <div class="contact-Qlink">
            <div class="contact-info">
              <h5 class="contact-info-title">Contact</h5>
              <p class="contact-info-text1">a POSCO Group Corporate Venture</p>
              <p class="contact-info-text2">
                용산구 한강대로 366 트윈시티 남산 612호<br />
                +82-2-3211-4374<br />
                info@i-esg.io
              </p>
            </div>
            <div class="Qlink">
              <h5 class="contact-info-title">Quick Links</h5>
              <ul class="footer-link">
                <a href="https://www.youtube.com/channel/UCRoZNFoYdCGUlqZR0UGkr_A" target="__blank"
                  ><i class="fab fa-youtube fa-lg"
                /></a>
                <a href="https://www.facebook.com/profile.php?id=100089226309495" target="__blank"
                  ><i class="fab fa-facebook-square fa-lg"
                /></a>
                <a
                  href="https://www.linkedin.com/company/i-esg/posts/?feedView=all"
                  target="__blank"
                  ><i class="fab fa-linkedin fa-lg"
                /></a>
              </ul>
              <ul>
                <div class="small_text1" @click="viewmodal = 1">Privacy Policy</div>
                <div class="small_text2" @click="viewmodal = 2">Terms of Service</div>
              </ul>
            </div>
          </div>
          <div class="copyright">COPYRIGHT(C) 2022 i-ESG ALL RIGHT RESERVED.</div>
        </div>
        <div class="updown"></div>
        <div class="inner-right" id="contact-box">
          <Submitform :modalshow="modalshow" :modaldata="modaldata" />
          <!-- <h1 class="title">궁금한 사항은 문의주세요.</h1>
        <form @submit.prevent="submitForm">
          <div class="input-box">
            <input type="text" v-model="form.name" placeholder="성함" required>
            <input v-model="form.company" placeholder="회사명" required>
            <input type="email" v-model="form.email" placeholder="e-mail" required >
            <input v-model="form.phone" placeholder="연락처" required>
            <textarea v-model="form.questions" placeholder="문의 사항" required></textarea>
          </div>
          <div class="check">
            <input type="checkbox" id="checkbox2" v-model="infochecked" required><label for="checkbox2">요청된 사항을 처리하기 위해 i-ESG가 위에 제출된 개인정보를 저장 및 처리하는 데 동의합니다.</label>
          </div>
          <button type="submit">문의하기</button>
        </form> -->
        </div>
        <Submodal :viewmodal="viewmodal" @closemodal="viewmodal = 0" />
      </div>
    </footer>

    <footer v-if="language == 'en'" class="beauty_footer section" id="contact">
      <div class="inner">
        <div class="inner-left">
          <div class="footer-intro">
            <div class="logo-img"></div>
            <p class="footer-intro-text1">
              i-ESG is a data-driven digital integrated solution company specialized in the ESG
              sector. To provide more practical support to company customers, we use the latest
              technologies for purposes such as big data analysis, AI algorithms, and blockchain
              technology. To better manage your ESG, i-ESG is the most adequate solution for SMEs.
            </p>
            <p class="footer-intro-text2"></p>
          </div>
          <div class="contact-Qlink">
            <div class="contact-info">
              <h5 class="contact-info-title">Contact</h5>
              <p class="contact-info-text1">a POSCO Group Corporate Venture</p>
              <p class="contact-info-text2">
                366, Hangang-daero, Yongsan-gu, Seoul, Republic of Korea<br />
                +82-2-3211-4374<br />
                info@i-esg.io
              </p>
            </div>
            <div class="Qlink">
              <h5 class="contact-info-title">Quick Links</h5>
              <ul class="footer-link">
                <a href="https://www.youtube.com/channel/UCRoZNFoYdCGUlqZR0UGkr_A" target="__blank"
                  ><i class="fab fa-youtube"
                /></a>
                <a href="https://www.facebook.com/profile.php?id=100089226309495" target="__blank"
                  ><i class="fab fa-facebook-square"
                /></a>
                <a
                  href="https://www.linkedin.com/company/i-esg/posts/?feedView=all"
                  target="__blank"
                  ><i class="fab fa-linkedin fa-lg"
                /></a>
              </ul>
              <ul>
                <div class="small_text1" @click="viewmodal = 1">Privacy Policy</div>
                <div class="small_text2" @click="viewmodal = 2">Terms of Service</div>
              </ul>
            </div>
          </div>
          <div class="copyright">COPYRIGHT(C) 2022 i-ESG ALL RIGHT RESERVED.</div>
        </div>
        <div class="updown"></div>
        <div class="inner-right" id="contact-box">
          <!-- <h1 class="title">Let us know how we can help</h1> -->
          <ContactModal></ContactModal>
          <Submitform :modalshow="modalshow" :modaldata="modaldataEN" :language="language" />
          <!-- <form action="" @submit.prevent="submitForm">
          <div class="input-box">
            <input type="text" v-model="form.name" placeholder="Name" required>
            <input v-model="form.company" placeholder="Company Name" required>
            <input type="email" v-model="form.email" placeholder="Business Email" required >
            <input v-model="form.phone" placeholder="Phone" required>
            <textarea v-model="form.questions" placeholder="Message" required></textarea>
          </div>
          <div class="check">
            <input type="checkbox" id="checkbox2" v-model="infochecked" required><label for="checkbox2">
              By clicking submit, you consent to communication regarding this inquiry and to the storing and processing of your personal data in accordance with our Privacy Policy.
            </label>
          </div>
          <button type="submit">Submit</button> -->
          <!-- <p v-if="errorshow">
            <ul>
                <li v-for="error in errors" :key="error" style="color: #f5b914; font-size: 13.5px; list-style: none;padding: 5px;"><b>{{ error }}</b></li>
            </ul>
          </p> -->
          <!-- </form> -->
        </div>
        <Submodal :viewmodal="viewmodal" @closemodal="viewmodal = 0" />
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios';
import Submodal from '../Modal/SubModal.vue';
import Submitform from '../Modal/Submitform.vue';

axios.defaults.baseURL = 'https://solution.i-esg.io:8000';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// const HOST = 'https://solution.i-esg.io:8000';
export default {
  name: 'Footer',
  components: { Submodal, Submitform },
  props: {
    menus: Array,
    language: String
  },
  data() {
    return {
      modalshow: true,
      modaldata: {
        Mtitle: '궁금한 사항은 문의주세요.',
        Mname: '담당자 이름 / 직급 (이름과 직급 사이에 슬래쉬(/) 기호 입력 필요)',
        Mposition: '담당자 직급',
        Mcompany: '회사명',
        Mdepartment: '담당 부서',
        Mphone: '연락처',
        Memail: '이메일 주소',
        Mquestions: '문의사항',
        Mbutton: '문의하기',
        Mtype: '홈페이지 문의사항'
      },
      modaldataEN: {
        Mtitle: 'Let us know how we can help',
        Mname: 'Name / Position',
        Mposition: 'Position',
        Mcompany: 'Company Name',
        Mdepartment: 'Department',
        Mphone: 'Phone',
        Memail: 'Business Email',
        Mquestions: 'Message',
        Mbutton: 'Submit',
        Mtype: '홈페이지 문의사항(EN)'
      },

      form: {
        name: '',
        email: '',
        company: '',
        phone: '',
        questions: ''
      },
      infochecked: false,
      viewmodal: 0
      // errors: [],
      // errorshow: false,
    };
  },
  methods: {
    // validatePhoneNumber() {
    //   const validationRegex = /^\d{10}$/;
    //   if (this.phoneNumber.match(validationRegex)) {
    //     this.isValidPhoneNumber = true;
    //   } else {
    //     this.isValidPhoneNumber = false;
    //   }
    // },
    // validationCheck() {
    //   this.errors = []
    //   if (!this.form.name) {
    //       this.errors.push("이름을 입력해주세요.")
    //   }
    //   if (!this.form.email) {
    //       this.errors.push("이메일을 입력해주세요.")
    //   }
    //   if (!this.form.company) {
    //       this.errors.push("회사명을 입력해주세요.")
    //   }
    //   if (!this.form.message) {
    //       this.errors.push("내용을 입력해주세요.")
    //   }
    //   if (!this.form.infochecked) {
    //       this.errors.push("개인정보 저장 및 처리에 동의해주세요.")
    //   }
    //   if (!this.errorshow) {
    //       this.errorshow = true
    //   }
    // },
    showAlert() {
      const title = this.language === 'en' ? 'Completed' : '완료되었습니다.';
      const text = this.language === 'en' ? 'Thank you' : '감사합니다.';
      this.$swal({
        icon: 'success',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
      })
    },
    showError() {
      const title = this.language === 'en' ? 'Transmission Error' : '전송오류';
      const text = this.language === 'en' ? 'Please try again.' : '다시 시도하십시오.';
      this.$swal({
        icon: 'failed',
        title: title,
        text: text,
        confirmButtonColor: '#000116'
      });
    },

    clearAll() {
      (this.form.name = ''),
        (this.form.email = ''),
        (this.form.company = ''),
        (this.form.phone = ''),
        (this.form.questions = ''),
        (this.infochecked = false);
    },
    async submitForm() {
      // this.validationCheck();
      // if (this.errors.length == 0) {
      try {
        const params = new URLSearchParams();
        for (let [key, value] of Object.entries(this.form)) {
          params.append([key], value);
        }
        const result = await axios.post('https://solution.i-esg.io/API/question/create', params);
        return result.data, this.showAlert();
      } catch (err) {
        console.log(err);
        this.showError();
      } finally {
        this.clearAll();
      }
      // axios.post('/API/question/create',saveData)
      //     .then((res) => {
      //         console.log(res.data)
      //         this.showAlert()
      //     })
      //     .catch((error) => {
      //         console.log(error)
      //         console.log(saveData)
      //         // this.showError()$
      //     }).finally(() => {
      //         this.clearAll();
      //     });
    }
  }
};
</script>

<style scoped src="./footer.css"></style>
